type Iso8601 = string

interface LastSaved {
  savedAt: Iso8601
  savedBy: Iso8601
  savedById: string
}

export interface ScheduleItem {
  id: string
  start: Iso8601
  end: Iso8601
  assetOperationModeId: number
  assetId: number
  isShutdownAvailable: boolean
  isTransitionTime: boolean
}

export type ScheduleItemDict = Record<string, ScheduleItem>

export interface Schedule {
  schedules: ScheduleItemDict
  scheduleId: number
  lastSaved: LastSaved
  isOptimizedScheduleAvailable: boolean
}

export interface SubmitSchedule {
  scheduleId: number
}
