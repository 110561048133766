export type Iso8601 = string

// Error related types and interfaces
export type ErrorResponse<F extends string = string, S extends string = string, E = undefined> = {
  detail?: string
  message?: string
  errors: Partial<{[key in F]: string}>
  status: S
  extendedData: E
}

export interface AuditFields {
  createdOn: Iso8601
  createdBy: string
  updatedOn?: Iso8601
  updatedBy?: string
}

export type AutocompleteOption = {id: string; label: string}

export type Nullable<T> = {
  [P in keyof T]: T[P] | null
}
