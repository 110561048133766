interface Plant {
  plantId: string
  plantName: string
  city: string
  country: string
  dispatchGroup: string
  plantNumber: string
  plantType: string
  state: string
  postalCode: string
  street: string
  lat: string
  lng: string
  onBaseName: string
}

export type PlantsResponse = Plant[]
